import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main, { mainHotels } from './components/Main/main';
import Polish from './components/Polish/Polish';
import Czech from './components/Czechy/Czechy';
import Wegry from './components/Węgry/Wegry';
import Slowacja from './components/Słowacja/Slowacja';
import Tips from './components/Tips/Tips';
import Review from './components/Rewievs/ReviewsSection';
import Transmisje from './components/Main/TransmisjeSection';
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer/Footer';
import Privacy from './components/Policy/Privacy';
import CookiePolicy from './components/Policy/CookiePolicy';
import styles from './styles.module.css';
import './global.css'; 
import SliderSection from './components/Main/SliderPage';

const App = () => {
  return (
    <div className={styles.appcontainer}>
      <Router>
        <NavBar />
        <div className={styles.content}>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/Polish" element={<Polish />} />
              <Route path="/Tips" element={<Tips description={'Kraje, w których dokonuje się rezerwacji hotelu'}/>} />
              <Route path="/Slider" element={<SliderSection hotels={mainHotels}/>} />
              <Route path="/Review" element={<Review />} />
              <Route path="/Transmisje" element={<Transmisje />} />
              <Route path="/Privacy" element={<Privacy />} />
              <Route path="/Czech" element={<Czech />} />
              <Route path="/Wegry" element={<Wegry />} />
              <Route path="/Slowacja" element={<Slowacja />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
            </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
  <App />
</React.StrictMode>);