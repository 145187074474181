import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from '../Main/main.module.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Pagination } from 'swiper/modules';

const SliderComponent = ({ images }) => {
  return (
    <Swiper
      spaceBetween={32}
      centeredSlides={true}
      slidesPerView={1.63}
      navigation
      loop={true}
      modules={[Navigation, Pagination]}
      breakpoints={{
        0: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        660: {
          slidesPerView: 1.2,
          spaceBetween: 32,
        },
        1160: {
          slidesPerView: 1.63,
          spaceBetween: 32,
        },
      }}
      className={styles.swiper}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <div className={styles.itemContainer}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: '100%' }}
              width={'919'}
              title={`Image ${index + 1}`}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderComponent;
