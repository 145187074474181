import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import close from '../Assets/close.svg';

import navbarstyles from './navbar.module.css'

const NavBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <nav>
        <div className={navbarstyles.burgerMenu} onClick={toggleMenu}>
          {isMenuOpen ? <img src={close} alt="close" className={navbarstyles.closeBtn} title='close' width={'30px'}/> : <>
            <div className={navbarstyles.bar}></div>
            <div className={navbarstyles.bar}></div>
            <div className={navbarstyles.bar}></div>
          </> }
        </div>
        <ul className={isMenuOpen ? navbarstyles.menuOpen : navbarstyles.menuClosed}>
          <li onClick={toggleMenu}><Link to="/">Strona główna</Link></li>
          <li onClick={toggleMenu}><Link to="/Tips">Wskazówki</Link></li>
          <li onClick={toggleMenu}><Link to="/Slider">Oferty</Link></li>
          <li onClick={toggleMenu}><Link to="/Review">Opinie</Link></li>
          <li onClick={toggleMenu}><Link to="/Transmisje">SportBar</Link></li>
          <li onClick={toggleMenu}><a href="#contacts">Łączność</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;