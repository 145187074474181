import React from 'react';
import styles from '../Main/main.module.css'; // Подключаем модуль стилей
import Czech1 from '../Assets/slowacja1.webp';
import Czech2 from '../Assets/slowacja2.webp';
import Czech3 from '../Assets/slowacja3.webp';
import Offerta1 from '../Assets/slowacjahotel1.webp';
import Offerta12 from '../Assets/slowacjahotel4.webp';
import Offerta13 from '../Assets/slowacjahotel5.webp';
import HotelPolski1 from '../Assets/slowacjahotel2.webp';
import HotelPolski12 from '../Assets/slowacjahotel6.webp';
import HotelPolski13 from '../Assets/slowacjahotel7.webp';
import HotelPolski2 from '../Assets/slowacjahotel3.webp';
import HotelPolski22 from '../Assets/slowacjahotel8.webp';
import HotelPolski23 from '../Assets/slowacjahotel9.webp';
import { HeroSection } from '../Main/main';
import { HotelsSectionSlider } from '../Main/SliderPage';
// Компонент для шапки

const slowacjaImages = [Czech3, Czech1, Czech2, Czech3, Czech1, Czech2 ];
const slowacjaHotels = [
  {
    name: "Marrol's Boutique Hotel",
    description: "Marrol's Boutique Hotel to wysoko oceniany hotel położony w malowniczym mieście Bratysława, stolicy Słowacji. Oferuje gościom luksusowy i przytulny pobyt w atmosferze elegancji i indywidualnego stylu.",
    rating: "9.1",
    address: "Tobrucká 4, 811 02 Bratysława, Słowacja",
    events: [
      {
        title: "Restauracja",
        description: "W hotelu znajduje się restauracja serwująca pyszne dania kuchni europejskiej."
      },
      {
        title: "Bar",
        description: "Zrelaksuj się w przytulnym hotelowym barze, delektując się koktajlami i lekkimi przekąskami."
      }
    ],
    images: [Offerta1, Offerta12, Offerta13],
    bookingLink: "https://www.booking.com/hotel/sk/marrol-s.pl.html"
  },
  {
    name: "Apartamenty Eurovea",
    description: "Eurovea Apartments to wysoko oceniany hotel położony w Bratysławie na Słowacji. Oferuje gościom przytulne i stylowe apartamenty w nowoczesnym kompleksie Eurovea, położonym nad brzegiem Dunaju.",
    rating: "9.4",
    address: "Pribinova 22, 811 09 Bratysława, Słowacja",
    events: [
      {
        title: "Spacery wzdłuż brzegów Dunaju",
        description: "Ciesz się spokojnymi spacerami wzdłuż brzegów Dunaju."
      },
      {
        title: "Kino",
        description: "Odwiedź nowoczesne kino z wygodnymi salami i szerokim wyborem filmów."
      }
    ],
    images: [HotelPolski1, HotelPolski12, HotelPolski13],
    bookingLink: "https://www.booking.com/hotel/sk/eurovea-secret.pl.html"
  },
  {
    name: "Flora Cozy Apartment",
    description: "Flora Cozy Apartment to wysoko oceniany hotel położony w Bratysławie na Słowacji. Oferuje gościom przytulne i komfortowe apartamenty w cichej i spokojnej części miasta, blisko terenów zielonych i centralnych ulic.",
    rating: "8.2",
    address: "Bajkalska 9B, Nove Mesto, 831 04 Bratysława, Słowacja",
    events: [
      {
        title: "Lokalizacja",
        description: "Bajkalska 9B, Nove Mesto, 831 04 Bratysława, Słowacja"
      }
    ],
    images: [HotelPolski2, HotelPolski22, HotelPolski23],
    bookingLink: "https://www.booking.com/hotel/sk/chors-apartments.pl.html?aid=356980&label=gog235jc-1DCAsozQFCEGNob3JzLWFwYXJ0bWVudHNIM1gDaLYBiAEBmAEeuAEXyAEV2AED6AEBiAIBqAIDuALo89WzBsACAdICJDdkNmEzNThhLWM3MGItNGFhYy1iZDA2LTg0MTM2MWY5YzgyYdgCBOACAQ&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
  }
];


const Main = () => (
  <main>
    <HeroSection images={slowacjaImages} title={'Slowacja'}/>
    <section className={styles.information}>
      <h2 className={styles.sectionHeader}>Hotele</h2>
      <HotelsSectionSlider hotels={slowacjaHotels} />
    </section>
  </main>
);

export default Main;