import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './tips.module.css';
import List1 from '../Assets/list1.webp';
import List2 from '../Assets/list2.webp';
import List3 from '../Assets/list3.webp';
import List4 from '../Assets/list4.webp';
import List1Mob from '../Assets/list1Mob.webp';
import List2Mob from '../Assets/list2Mob.webp';
import List3Mob from '../Assets/list3Mob.webp';
import List4Mob from '../Assets/list4Mob.webp';
import HotelsSlider from '../HotelsSlider/HotelsSlider';
import LazyLoad from 'react-lazyload';

const TipsSection = ({ description, useSliderInModal }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 510);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 510);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const images = isMobile
    ? [List1Mob, List2Mob, List3Mob, List4Mob]
    : [List1, List2, List3, List4];

  return (
    <section className={styles.tips}>
      <div className={styles.tipsContainer}>
        <h2>Wskazówki</h2>
        <h3>{description}</h3>
        {isMobile && useSliderInModal ? (
          <HotelsSlider images={images} />
        ) : (
          <div className={styles.countryGrid}>
            <Link to="/Polish" className={styles.country}>
              <LazyLoad height={200} offset={100}>
                <img src={images[0]} width="544" height="300" alt="Polska" title="Polska" />
              </LazyLoad>
            </Link>
            <Link to="/Czech" className={styles.country}>
              <LazyLoad height={200} offset={100}>
                <img src={images[1]} width="544" height="300" alt="Czechy" title="Czechy" />
              </LazyLoad>
            </Link>
            <a href="/Slowacja" className={styles.country}>
              <LazyLoad height={200} offset={100}>
                <img src={images[2]} width="544" height="300" alt="Słowacja" title="Słowacja" />
              </LazyLoad>
            </a>
            <Link to="/Wegry" className={styles.country}>
              <LazyLoad height={200} offset={100}>
                <img src={images[3]} width="544" height="300" alt="Wengry" title="Wengry" />
              </LazyLoad>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default React.memo(TipsSection);
