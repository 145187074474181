import React from 'react';
import styles from './footer.module.css';
import { Link } from 'react-router-dom';
import location from '../Assets/locationbrown.svg';
import mail from '../Assets/mailbrown.svg';

const Footer = () => {
  return (
    <footer className={styles.footer} id='contacts'>
      <div className={styles['footer-content']}>
        <div className={styles['contact-info']}>
          <p>
            „sloturinorocoase.com” – wykorzystuje pliki cookie, aby zapewnić Ci
            najlepsze doświadczenia. Pozostając na stronie wyrażasz zgodę na
            używanie jej plików cookies.
          </p>
          <Link to='cookie-policy'><div className={styles.more}>Więcej szczegółów</div></Link>
        </div>
        <div className={styles['legal-links']}>
          <div className={styles.adress}>
            <p><img src={location} alt="location" title='location' width={'24px'}/> Pawia 5, 31-154 Kraków, Polska</p>
            <p>
              <img src={mail} alt="mail" title='mail' width={'24px'}/>
              <a href='mailto:sloturinorocoase@gmail.com'>
                sloturinorocoase@gmail.com
              </a>
            </p>
          </div>
          <Link to='Privacy'>Polityka prywatności </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
