import React, { useState } from 'react';
import styles from '../Main/main.module.css'; // Подключаем модуль стилей
import Polska1 from '../Assets/Polska1.webp';
import Polska2 from '../Assets/Polska2.webp';
import Polska3 from '../Assets/Polska3.webp';
import Offerta1 from '../Assets/oferta1.webp';
import Offerta12 from '../Assets/oferta12.webp';
import Offerta13 from '../Assets/oferta13.webp';
import HotelPolski1 from '../Assets/hotelpolski1.webp';
import HotelPolski12 from '../Assets/hotelpolski12.webp';
import HotelPolski13 from '../Assets/hotelpolski13.webp';
import HotelPolski2 from '../Assets/hotelpolski2.webp';
import HotelPolski22 from '../Assets/hotelpolski22.webp';
import HotelPolski23 from '../Assets/hotelpolski23.webp';
import { HeroSection } from '../Main/main';
import { HotelsSectionSlider } from '../Main/SliderPage';




const polishImages = [Polska3, Polska1, Polska2, Polska3, Polska1, Polska2];
const polishHotels = [
  {
    name: "Sofitel Grand Sopot",
    description: "Sofitel Grand Sopot to wysoko oceniany hotel położony nad Morzem Bałtyckim w polskim mieście Sopot. Oferuje gościom niezapomniane wakacje w atmosferze luksusu i elegancji.",
    rating: "9.0",
    address: "Powstańców Warszawy 12/14, Dolny Sopot, 81-718 Sopot, Polska",
    events: [
      {
        title: "Wycieczki",
        description: "Hotel oferuje bogatą ofertę wycieczek po Sopocie, Gdańsku i innych ciekawych miejscach w Polsce."
      },
      {
        title: "Taras",
        description: "Ciesz się słonecznymi dniami na tarasie Sofitel Grand Sopot z przepięknym widokiem na Morze Bałtyckie."
      }
    ],
    images: [Offerta1, Offerta12, Offerta13],
    bookingLink: "https://www.booking.com/hotel/pl/sofitelgrandsopot.pl.html"
  },
  {
    name: "Hotel Testa",
    description: "Hotel Testa to wysoko oceniany hotel położony w Krakowie. Oferuje gościom przytulny i stylowy pobyt w atmosferze nowoczesnego designu i niepowtarzalnego charakteru.",
    rating: "9.1",
    address: "Kilińskiego 11A, Dolny Sopot, 81-772 Sopot, Polska",
    events: [
      {
        title: "Centrum fitness",
        description: "Zadbaj o formę w najnowocześniejszym centrum fitness."
      },
      {
        title: "Wycieczki",
        description: "Hotel może pomóc w organizacji wycieczek po Krakowie i okolicach."
      }
    ],
    images: [HotelPolski1, HotelPolski12, HotelPolski13],
    bookingLink: "https://www.booking.com/hotel/pl/villa-testa.pl.html"
  },
  {
    name: "Sheraton Sopot Hotel",
    description: "Sheraton Sopot Hotel to wysoko oceniany hotel położony w mieście Sopot, na polskim wybrzeżu Bałtyku. Oferuje gościom luksusowy pobyt w atmosferze elegancji i niepowtarzalnego stylu.",
    rating: "8.9",
    address: "ul. Powstańców Warszawy 10, Dolny Sopot, 81-718 Sopot, Polska",
    events: [
      {
        title: "Restauracja",
        description: "W hotelu znajduje się restauracja serwująca wyśmienite dania kuchni polskiej i europejskiej."
      },
      {
        title: "Basen",
        description: "Zrelaksuj się w przytulnym hotelowym basenie, ciesząc się świeżym powietrzem i pięknymi widokami."
      }
    ],
    images: [HotelPolski2, HotelPolski22, HotelPolski23],
    bookingLink: "https://www.booking.com/hotel/pl/sheratonsopot.pl.html?aid=356980&label=gog235jc-1DCAsotgFCDXNoZXJhdG9uc29wb3RIM1gDaLYBiAEBmAEeuAEXyAEV2AED6AEBiAIBqAIDuAKZ89WzBsACAdICJGJlOTc4YjgzLTc5NjMtNDYzYi05Y2JlLTkxYmMzM2QxNzQ1ZNgCBOACAQ&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
  }
];

const Main = () => (
  <main>
    <HeroSection images={polishImages} title={'Polska'}/>
    <section className={styles.information}>
      <h2 className={styles.sectionHeader}>Hotele</h2>
      <HotelsSectionSlider hotels={polishHotels} />
    </section>
  </main>
);

export default Main;