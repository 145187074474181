import React from 'react';
import styles from './policy.module.css';

const CookiePolicy = () => {
  return (
    <div className={styles.privacyPolicyContainer}>
      <div className={styles['privacy-policy']}>
        <h1>Polityka dotycząca plików cookie</h1>
        <p>
          To jest polityka plików cookies dla sloturinorocoase.com, dostępna pod
          adresem sloturinorocoase.com
        </p>
        <h2>Co to są pliki cookie</h2>
        <p>
          Jak zwykle w przypadku prawie wszystkich profesjonalnych witryn
          internetowych, ta witryna wykorzystuje pliki cookie, które są małymi
          plikami który jest pobierany na Twój komputer, aby poprawić Twoje
          wrażenia. Na tej stronie opisano, jakie informacje oni mają zbieramy,
          w jaki sposób z nich korzystamy i dlaczego czasami musimy przechowywać
          te pliki cookie. Będziemy także dzielić się tym, jak możemy
          uniemożliwić przechowywanie tych plików cookies, jednakże może to
          osłabić lub „zepsuć” niektóre elementy stron internetowych miasta
          funkcjonalność.
        </p>
        <h2>Jak używamy plików cookie</h2>
        <p>
          Korzystamy z plików cookie z różnych powodów opisanych poniżej.
          Niestety w większości przypadków branża taka nie jest domyślne opcje
          wyłączania plików cookie bez całkowitego wyłączenia funkcjonalności i
          funkcji takich jak dodają je do tej strony. Zaleca się pozostawienie
          wszystkich plików cookie, jeśli nie jesteś pewien, czy ich
          potrzebujesz, czy nie nie, jeśli są wykorzystywane do świadczenia
          usługi, z której korzystasz.
        </p>
        <h2>Wyłącz pliki cookie</h2>
        <p>
          Możesz zapobiec zapisywaniu plików cookies poprzez zmianę ustawień
          swojej przeglądarki (patrz pomoc przeglądarki Do jak to zrobić).
          Należy pamiętać, że wyłączenie plików cookie będzie miało wpływ na
          funkcjonalność tej i wielu innych funkcji odwiedzane strony
          internetowe. Wyłączenie plików cookie zwykle powoduje również
          wyłączenie niektórych funkcji I funkcje na tej stronie. Dlatego nie
          zaleca się wyłączania plików cookie.
        </p>
        <h2>Informacyjne pliki cookie, które ustawiamy</h2>
        <p>Pliki cookie ustawień witryny</p>
        <p>
          Aby zapewnić Ci dobre doświadczenia w korzystaniu z tej witryny,
          oferujemy funkcję umożliwiającą wprowadzenie Twojego ustawienia
          działania tej witryny internetowej podczas korzystania z niej. Aby
          zapamiętać Twoje preferencje, je musimy położyć pliki cookie, dzięki
          którym informacje te będą mogły zostać odzyskane przy każdej
          interakcji z daną witryną twój ustawienia.
        </p>
        <h2>Pliki cookie stron trzecich</h2>
        <p>
          W niektórych szczególnych przypadkach używamy również plików cookie
          pochodzących od zaufanych stron trzecich. Następny szczegóły sekcji
          jakie pliki cookie stron trzecich możesz napotkać na tej stronie.
        </p>
        <p>
          Ta witryna korzysta z Google Analytics, jednego z najczęściej
          używanych i zaufanych narzędzi rozwiązań analitycznych na świecie
          online, ponieważ pomagasz nam zrozumieć, w jaki sposób korzystasz z
          witryny i jak możemy ulepszyć Twoją doświadczenie. Te pliki cookie
          mogą śledzić na przykład czas spędzany w witrynie i na jakich stronach
          Ty ich odwiedzasz, więc my możemy nadal tworzyć ciekawe treści.
        </p>
        <p>
          Więcej informacji na temat plików cookie Google Analytics można
          znaleźć na oficjalnej stronie Google Analytics.
        </p>
        <h2>Więcej informacji</h2>
        <p>
          Mamy nadzieję, że wyjaśniliśmy Ci sprawę i jak wspomnieliśmy
          wcześniej, jeśli jest coś takiego nie jesteś pewien, czy ich
          potrzebujesz, czy nie, zazwyczaj bezpieczniej jest pozostawić na
          wszelki wypadek włączoną obsługę plików cookie interakcje z jedną z
          funkcji, z których korzystasz na naszej stronie internetowej.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
