import React from 'react';
import styles from '../Main/main.module.css'; 
import Czech1 from '../Assets/Czech1.webp';
import Czech2 from '../Assets/Czech2.webp';
import Czech3 from '../Assets/Czech3.webp';
import Offerta1 from '../Assets/czechhotel1.webp';
import Offerta12 from '../Assets/czechhotel2.webp';
import Offerta13 from '../Assets/czechhotel3.webp';
import HotelPolski1 from '../Assets/czechhotel4.webp';
import HotelPolski12 from '../Assets/czechhotel5.webp';
import HotelPolski13 from '../Assets/czechhotel6.webp';
import HotelPolski2 from '../Assets/czechhotel7.webp';
import HotelPolski22 from '../Assets/czechhotel8.webp';
import HotelPolski23 from '../Assets/czechhotel9.webp';
import { HotelsSectionSlider } from '../Main/SliderPage';
import { HeroSection } from '../Main/main';

const czechImages = [Czech3, Czech1, Czech2, Czech3, Czech1, Czech2];
const czechHotels = [
  {
    name: "Buddha-Bar Hotel Praga",
    description: "Buddha-Bar Hotel Prague to wysoko oceniany hotel położony w samym sercu Pragi, zaledwie kilka kroków od Rynku Starego Miasta. Oferuje gościom luksusowy pobyt w atmosferze orientalnego szyku i wyrafinowania.",
    rating: "9.1",
    address: "Jakubská 649/8, Praga, 110 00, Czechy",
    events: [
      {
        title: "Spa",
        description: "Zrelaksuj się i oddaj zabiegom spa w stylowym Spa Buddha-Bar."
      },
      {
        title: "Restauracje i bary",
        description: "W hotelu znajdują się dwie restauracje i bar oferujący wyśmienitą kuchnię."
      }
    ],
    images: [Offerta1, Offerta12, Offerta13],
    bookingLink: "https://www.booking.com/hotel/cz/buddha-bar-prague.pl.html"
  },
  {
    name: "Almanac X Alcron Praga",
    description: "Almanac X Alcron Praga to wysoko oceniany hotel położony w Pradze, w Czechach. Oferuje gościom luksusowy pobyt w atmosferze elegancji i historycznego uroku.",
    rating: "9.2",
    address: "Stepanska 40, Praga, 110 00, Czechy",
    events: [
      {
        title: "Wycieczki",
        description: "Hotel może pomóc w organizacji wycieczek po Pradze i okolicach."
      },
      {
        title: "Spa",
        description: "Podaruj sobie wyjątkowe doświadczenie spa w stylowym hotelowym spa."
      }
    ],
    images: [HotelPolski1, HotelPolski12, HotelPolski13],
    bookingLink: "https://www.booking.com/hotel/cz/alcron.pl.html"
  },
  {
    name: "Hotel Paris Praga",
    description: "Hotel Paris Praga to wysoko oceniany hotel położony w samym sercu Pragi, zaledwie kilka kroków od Rynku Starego Miasta. Oferuje gościom przytulny i stylowy pobyt w atmosferze elegancji i romantyzmu.",
    rating: "9.0",
    address: "U Obecniho domu 1, Praga, 110 00, Czechy",
    events: [
      {
        title: "Spa",
        description: "Podaruj sobie wyjątkowe doświadczenie spa w stylowym hotelowym spa."
      },
      {
        title: "Wycieczki",
        description: "Hotel może pomóc w organizacji wycieczek po Pradze i okolicach."
      }
    ],
    images: [HotelPolski2, HotelPolski22, HotelPolski23],
    bookingLink: "https://www.booking.com/hotel/cz/pariz.pl.html?aid=356980&label=gog235jc-1DCAsoOkIFcGFyaXpIM1gDaLYBiAEBmAEeuAEXyAEV2AED6AEBiAIBqAIDuALP9dWzBsACAdICJDUyM2RmZjgyLWJhNzMtNGMwOC1hODlhLTM5ZmNhYTQ1ZjQxYtgCBOACAQ&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
  }
];


const Main = () => (
  <main>

<HeroSection images={czechImages} title={'Czechy'}/>
<section className={styles.information}>
      <h2 className={styles.sectionHeader}>Hotele</h2>
      <HotelsSectionSlider hotels={czechHotels} />
    </section>
  </main>
);

export default Main;