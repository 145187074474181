import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from '../Main/main.module.css';
import { Navigation, Pagination } from 'swiper/modules';

const HotelsSlider = ({ images }) => {
  return (
    <div className={styles.imageContainer}>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        loop={true}
        modules={[Navigation, Pagination]}
        className={styles.hotelsSlider}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className={styles.imageWrapper}>
              <img
                src={image}
                alt={`hotel-image`}
                className={styles.image}
                title='hotel Image'
                loading="lazy"
              />
            </div>
          </SwiperSlide>
        ))}
        <div className='swiper-button-prev hotels-nav'></div>
        <div className='swiper-button-next hotels-nav'></div>
        <div className='swiper-pagination hotels-pagination'></div>
      </Swiper>
    </div>
  );
};

export default HotelsSlider;
