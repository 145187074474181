import React from 'react';
import styles from './policy.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicyContainer}>
      <div className={styles['privacy-policy']}>
        <h1>Polityka prywatności dla sloturinorocoase.com</h1>
        <p>Na stronie sloturinorocoase.com, dostępnej z witryny sloturinorocoase.com, jednym z naszych głównych priorytetów jest prywatność naszych gości. Niniejszy dokument Polityki prywatności zawiera informacje o rodzajach informacji gromadzonych i rejestrowanych przez sloturinorocoase.com oraz o tym, w jaki sposób je wykorzystujemy.</p>
        <p>Jeśli masz dodatkowe pytania lub potrzebujesz więcej informacji na temat naszej Polityki prywatności, nie wahaj się z nami <a href="#contacts">skontaktować</a>.</p>
        <h2>Pliki dziennika</h2>
        <p>sloturinorocoase.com postępuje zgodnie ze standardową procedurą korzystania z plików dziennika. Pliki te rejestrują odwiedzających, gdy odwiedzają strony internetowe. Wszystkie firmy hostingowe to robią i stanowią część analityki usług hostingowych. Informacje gromadzone przez pliki dziennika obejmują adresy protokołu internetowego (IP), typ przeglądarki, dostawcę usług internetowych (ISP), datę i godzinę, strony odsyłające/wyjściowe i ewentualnie liczbę kliknięć. Nie są one powiązane z żadnymi informacjami umożliwiającymi identyfikację osobistą. Celem tych informacji jest analiza trendów, administrowanie serwisem, śledzenie ruchu użytkowników w serwisie oraz zbieranie informacji demograficznych. Nasza Polityka Prywatności została stworzona przy pomocy Generatora Polityki Prywatności.</p>
        <h2>Plik cookie Google DoubleClick DART</h2>
        <p>Google jest jednym z zewnętrznych dostawców naszej witryny. Wykorzystuje również pliki cookie, zwane plikami cookie DART, do wyświetlania reklam osobom odwiedzającym naszą witrynę na podstawie ich wizyty na stronie www.website.com i innych witrynach w Internecie. Jednakże odwiedzający mogą zrezygnować z używania plików cookie DART, odwiedzając Politykę prywatności reklam i sieci partnerskiej Google pod następującym adresem URL.</p>
        <h2>Nasi partnerzy reklamowi</h2>
        <p>Niektórzy reklamodawcy w naszej witrynie mogą używać plików cookie i sygnałów nawigacyjnych. Lista naszych partnerów reklamowych znajduje się poniżej. Każdy z naszych partnerów reklamowych ma własną Politykę prywatności dotyczącą zasad dotyczących danych użytkowników. Aby ułatwić dostęp, poniżej zamieściliśmy hiperłącze do ich Polityki prywatności.</p>
        <h2>Polityka prywatności stron trzecich</h2>
        <p>Polityka prywatności sloturinorocoase.com nie ma zastosowania do innych reklamodawców ani stron internetowych. Dlatego zalecamy zapoznanie się z odpowiednimi Politykami prywatności tych serwerów reklamowych stron trzecich w celu uzyskania bardziej szczegółowych informacji. Może obejmować ich praktyki i instrukcje dotyczące rezygnacji z niektórych opcji.</p>
        <p>Możesz wyłączyć pliki cookie w indywidualnych opcjach przeglądarki. Bardziej szczegółowe informacje na temat zarządzania plikami cookies w poszczególnych przeglądarkach internetowych można znaleźć na stronach poszczególnych przeglądarek. Czym są pliki cookie?</p>
        <h2>Informacje dla dzieci</h2>
        <p>Kolejnym naszym priorytetem jest zapewnienie dzieciom ochrony podczas korzystania z Internetu. Zachęcamy rodziców i opiekunów do obserwowania, uczestniczenia i/lub monitorowania i kierowania ich aktywnością w Internecie.</p>
        <p>sloturinorocoase.com nie zbiera świadomie żadnych danych osobowych od dzieci poniżej 13 roku życia. Jeśli uważasz, że Twoje dziecko przekazało tego rodzaju informacje na naszej stronie internetowej, gorąco zachęcamy Cię do natychmiastowego skontaktowania się z nami, a my dołożymy wszelkich starań, aby niezwłocznie usunąć takie informacje z naszych rejestrów.</p>
        <h2>Tylko polityka prywatności online</h2>
        <p>Niniejsza Polityka prywatności ma zastosowanie wyłącznie do naszej działalności online i jest ważna dla osób odwiedzających naszą stronę internetową w odniesieniu do informacji, które udostępniają i/lub gromadzą w sloturinorocoase.com. Niniejsza polityka nie ma zastosowania do jakichkolwiek informacji gromadzonych w trybie offline lub za pośrednictwem kanałów innych niż ta strona internetowa.</p>
        <h2>Zgoda</h2>
        <p>Korzystając z naszej witryny, wyrażasz zgodę na naszą Politykę prywatności i akceptujesz jej Warunki.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;