import React from 'react';
import styles from './main.module.css';
import Tips from '../Tips/Tips';
import SliderSection from './SliderPage';
import ReviewsSection from '../Rewievs/ReviewsSection';
import SliderComponent from '../BigSlider/BigSlider';
import TransmisjeSection from './TransmisjeSection';

import Offerta1 from '../Assets/oferta1Mob.webp'; //заменить
import Offerta12 from '../Assets/oferta12Mob.webp'; //заменить
import Offerta13 from '../Assets/oferta13Mob.webp'; //заменить
import Offerta2 from '../Assets/oferta2.webp';
import Offerta22 from '../Assets/oferta22.webp';
import Offerta23 from '../Assets/oferta23.webp';
import Offerta3 from '../Assets/oferta3.webp';
import Offerta32 from '../Assets/oferta32.webp';
import Offerta33 from '../Assets/oferta33.webp';
import Offerta4 from '../Assets/oferta4.webp';
import Offerta42 from '../Assets/oferta42.webp';
import Offerta43 from '../Assets/oferta43.webp';

import Header from '../Assets/Header.webp';
import Header2 from '../Assets/Header2.webp';
import Header3 from '../Assets/Header3.webp';

import HeaderMob from '../Assets/HeaderMob.webp';
import Header2Mob from '../Assets/Header2Mob.webp';
import Header3Mob from '../Assets/Header3Mob.webp';

import WskazowkiModal from '../Modals/WskazowkiModal';
import CallbackModal from '../Modals/CallbackModal';

const mainImages = [Header, Header2, Header3, Header, Header2, Header3];
const mobileImages = [HeaderMob, Header2Mob, Header3Mob, HeaderMob, Header2Mob, Header3Mob]
export const mainHotels = [
  {
    name: "Sofitel Grand Sopot",
    description: "Sofitel Grand Sopot to wysoko oceniany hotel położony nad Morzem Bałtyckim w polskim mieście Sopot. Oferuje gościom niezapomniane wakacje w atmosferze luksusu i elegancji.",
    rating: "9.0",
    address: "Powstańców Warszawy 12/14, Dolny Sopot, 81-718 Sopot, Polska",
    events: [
      {
        title: "Wycieczki",
        description: "Hotel oferuje bogatą ofertę wycieczek po Sopocie, Gdańsku i innych ciekawych miejscach w Polsce."
      },
      {
        title: "Taras",
        description: "Ciesz się słonecznymi dniami na tarasie Sofitel Grand Sopot z przepięknym widokiem na Morze Bałtyckie."
      }
    ],
    images: [Offerta1, Offerta12, Offerta13],
    bookingLink: "https://www.booking.com/hotel/pl/sofitelgrandsopot.pl.html"
  },
  {
    name: "Buddha-Bar Hotel Praga",
    description: "Buddha-Bar Hotel Prague to wysoko oceniany hotel położony w samym sercu Pragi, zaledwie kilka kroków od Rynku Starego Miasta. Oferuje gościom luksusowy pobyt w atmosferze orientalnego szyku i wyrafinowania.",
    rating: "9.1",
    address: "Jakubská 649/8, Praga, 110 00, Czechy",
    events: [
      {
        title: "Spa",
        description: "Zrelaksuj się i oddaj zabiegom spa w stylowym Spa Buddha-Bar."
      },
      {
        title: "Restauracje i bary",
        description: "W hotelu znajdują się dwie restauracje i bar oferujący wyśmienitą kuchnię."
      }
    ],
    images: [Offerta2, Offerta22, Offerta23],
    bookingLink: "https://www.booking.com/hotel/cz/buddha-bar-prague.pl.html"
  },
  {
    name: "Marrol's Boutique Hotel",
    description: "Marrol's Boutique Hotel to wysoko oceniany hotel położony w malowniczym mieście Bratysława, stolicy Słowacji. Oferuje gościom luksusowy i przytulny pobyt w atmosferze elegancji i indywidualnego stylu.",
    rating: "9.1",
    address: "Tobrucká 4, 811 02 Bratysława, Słowacja",
    events: [
      {
        title: "Restauracja",
        description: "W hotelu znajduje się restauracja serwująca pyszne dania kuchni europejskiej."
      },
      {
        title: "Bar",
        description: "Zrelaksuj się w przytulnym hotelowym barze, delektując się koktajlami i lekkimi przekąskami."
      }
    ],
    images: [Offerta3, Offerta32, Offerta33],
    bookingLink: "https://www.booking.com/hotel/sk/marrol-s.pl.html"
  },
  {
    name: "Hotel Clark Budapest",
    description: "Hotel Clark Budapest – Adults Only to wysoko oceniany hotel położony w samym sercu Budapesztu, zaledwie kilka kroków od Dunaju i słynnego mostu Széchenyi. Oferuje gościom luksusowe i romantyczne wakacje w atmosferze elegancji i prywatności.",
    rating: "9.2",
    address: "1013 Budapeszt, Clark Adam tér 1., Węgry",
    events: [
      {
        title: "Spa",
        description: "Podaruj sobie wyjątkowe doświadczenie spa w stylowym hotelowym spa."
      },
      {
        title: "Centrum fitness",
        description: "Zadbaj o formę w najnowocześniejszym centrum fitness, wyposażonym w najnowocześniejszy sprzęt."
      }
    ],
    images: [Offerta4, Offerta42, Offerta43],
    bookingLink: "https://www.booking.com/hotel/hu/clark-budapest.pl.html"
  }
];

export const HeroSection = ({images, title, header1, header2}) => {

  return (
    <section className={styles.hero}>
      <p className={styles.header}>{header1}</p>
      <h1>{title}</h1>
      <p className={styles.headerDescription}>{header2}</p>
      <SliderComponent images={images}/>
    </section>
  );
};



const Main = () => {
  const isMobile = window.innerWidth < 475;
return (
  <main>
    {/* <WskazowkiModal/> */}
    {/* <CallbackModal/> */}
    <HeroSection images={isMobile ? mobileImages : mainImages} title={'Sloturinorocoase'} header1={'Podróżuj swoimi marzeniami - jednym kliknięciem!'} header2={'Sloturinorocoase to zespół doświadczonych profesjonalistów świadczących usługi rezerwacji hoteli i podróży na całym świecie.'}/>
    <Tips description={'Kraje, w których dokonuje się rezerwacji hotelu'} useSliderInModal={false}/>
    <SliderSection hotels={mainHotels}/>
    <ReviewsSection />
    <TransmisjeSection/>
  </main>
);
}

export default Main;