import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import styles from './reviews.module.css';
import Opinion1 from '../Assets/opinion1.webp';
import Opinion2 from '../Assets/opinion2.webp';
import Opinion3 from '../Assets/opinion3.webp';
import fiveStars from '../Assets/5stars.svg';
import fourStars from '../Assets/4stars.svg';

const reviews = [
  {
    id: 1,
    name: 'Gwalbert Mrozowski',
    text: 'Dziękujemy Sloturinorocoase za pomoc w rezerwacji hotelu w Pradze! Wszystko poszło idealnie, pokój był piękny, a cena bardzo dobra.',
    country: 'Polska',
    rating: fiveStars,
    raitingNum: '5.0',
    image: Opinion1 
  },
  {
    id: 2,
    name: 'Jadwiga Bojko',
    text: 'Byłam zachwycona usługami Sloturinorocoase. Pomogli mi znaleźć idealny hotel w Krakowie i zorganizować wszystkie niezbędne wycieczki. Polecam każdemu!',
    country: 'Polska',
    rating: fiveStars,
    raitingNum: '5.0',
    image: Opinion2
  },
  {
    id: 3,
    name: 'Włodzisław Bartosik',
    text: 'Zarezerwowałem hotel w Budapeszcie przez Sloturinorocoase i byłem bardzo zadowolony z rezultatu. Proces rezerwacji był prosty i szybki, a cena była bardzo atrakcyjna.',
    country: 'Polska',
    rating: fourStars,
    raitingNum: '4.0',
    image: Opinion3
  }
];

const ReviewsSection = () => {
  return (
    <section className={styles.reviewsSection}>
      <div className={styles.reviewsWrapper}>
        <h2 className={styles.title}>Opinie</h2>
        <div className={styles.reviewsContainer}>
          {reviews.map((review, index) => (
            <div key={index} className={styles.reviewCard}>
              <div className={styles.reviewAuthor}>
                <img src={review.image} alt={review.name} className={styles.imagerev} title='author' width={'65px'}/>
                <h3 className={styles.name}>{review.name}</h3>
              </div>
              <p className={styles.text}>{review.text}</p>
              <div className={styles.rating}>
                <p className={styles.country}>{review.country}</p>
                <div className={styles.raitingContainer}>
                  <img src={review.rating} alt="raiting" title='raiting'/>
                  <div className={styles.ratingNumber}>{review.raitingNum}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.reviewsContainer}>
        <Swiper
      spaceBetween={30}
      slidesPerView={1}
      centeredSlides={true}
      loop={true}
      pagination={{
        el: '.swiper-pagination',
        clickable: true,
      }}
      modules={[Navigation, Pagination]}
      className={styles.opinieSlider}
    >
      {reviews.map((review) => (
        <SwiperSlide  key={review.id}>
          <div className={styles.reviewCardSlide} >
          <div className={styles.reviewAuthor}>
                <img src={review.image} alt={review.name} className={styles.imagerev} title='author'/>
                <h3 className={styles.name}>{review.name}</h3>
              </div>
              <p className={styles.text}>{review.text}</p>
              <div className={styles.rating}>
                <p className={styles.country}>{review.country}</p>
                <div className={styles.raitingContainer}>
                  <img src={review.rating} alt="raiting" title='raiting'/>
                  <div className={styles.ratingNumber}>{review.raitingNum}</div>
                </div>
              </div>
          </div>
        </SwiperSlide>
      ))}
      <div className='swiper-pagination reviews-pagination'></div>
    </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;