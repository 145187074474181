import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from '../Main/main.module.css';
import { Pagination, Navigation } from 'swiper/modules';

import spain from '../Assets/spaincircle.svg';
import france from '../Assets/francecircle.svg';
import germany from '../Assets/germancircle.svg';
import italy from '../Assets/italycircle.svg';

const TransmisjeSlider = () => {
  return (
    <div className={styles.transmisjeSliderWrapper}>
      <Swiper
        spaceBetween={15}
        slidesPerView={2}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Navigation]}
        loop={true}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          1160: {
            slidesPerView: 2,
          },
        }}
        className={styles.transmisjeSlider}
      >
        <SwiperSlide className='transmisjeSwiperSlide'>
          <div className={styles.transmisjeSlide}>
            <div className={styles.slideTransmisjeContent}>
              <div>
                <div className={styles.league}>
                  Hiszpania – Primera. 33. runda
                </div>
                <div className={styles.match}>
                  <img src={spain} alt='spain' title='spain' width={32} height={34}/>
                  Real Sociedad – Real Madrid
                </div>
              </div>
              <div className={styles.time}>22:00</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='transmisjeSwiperSlide'>
          <div className={styles.transmisjeSlide}>
            <div className={styles.slideTransmisjeContent}>
              <div>
                <div className={styles.league}>
                  Francja – Ligue 1. 31. runda
                </div>
                <div className={styles.match}>
                  {' '}
                  <img src={france} alt='france' title='france' />
                  Montpellier – Nantes
                </div>
              </div>
              <div className={styles.time}>22:00</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='transmisjeSwiperSlide'>
          <div className={styles.transmisjeSlide}>
            <div className={styles.slideTransmisjeContent}>
              <div>
                <div className={styles.league}>
                  Germany - Bundesliga. 31st round
                </div>
                <div className={styles.match}>
                  {' '}
                  <img src={germany} alt='germany' title='germany'/>
                  Bochum – Hoffenheim
                </div>
              </div>
              <div className={styles.time}>21:30</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='transmisjeSwiperSlide'>
          <div className={styles.transmisjeSlide}>
            <div className={styles.slideTransmisjeContent}>
              <div>
                <div className={styles.league}>Italy - Serie A. 34th round</div>
                <div className={styles.match}>
                  {' '}
                  <img src={italy} alt='italy' title='italy' />
                  Frosinone – Salernitana
                </div>
              </div>
              <div className={styles.time}>21:45</div>
            </div>
          </div>
        </SwiperSlide>
        <div className='swiper-button-prev transmisje-nav'></div>
        <div className='swiper-button-next transmisje-nav'></div>
        <div className='swiper-pagination transmisje-pagination'></div>
      </Swiper>
    </div>
  );
};

export default TransmisjeSlider;
