import React from 'react';
import styles from './main.module.css';
import like from '../Assets/like.svg';
import location from '../Assets/location.svg';
import arrow from '../Assets/white-arrow.svg';
import HotelsSlider from '../HotelsSlider/HotelsSlider';

export const HotelsSectionSlider = ({hotels}) => {
  return (
    <>
    {hotels.map((hotel, index) => (
      <div key={index} className={styles.rectangle}>
        <div className={styles.textBlock}>
          <div className={styles.offersTitle}>
            <div>{index + 1}. {hotel.name}</div>
          </div>
          <div className={styles.description}>{hotel.description}</div>
          <div className={styles.adinfo}>
            <div className={styles.rating}>
              <img src={like} alt="rating" title='raiting' width={24} height={24}/> {hotel.rating}
            </div>
            <div className={styles.address}>
              <img src={location} alt="location" title='location' width={24} height={24}/> {hotel.address}
            </div>
          </div>
        </div>
        <div className={styles.textBlock}>
          <div className={styles.offersTitle}><div>Wydarzenia</div></div>
          {hotel.events.map((event, eventIndex) => (
            <React.Fragment key={eventIndex}>
              <h2>{event.title}</h2>
              <div className={styles.greyDescription}>{event.description}</div>
            </React.Fragment>
          ))}
          <button className={styles.bookButton}>
            <a href={hotel.bookingLink}>Zarezerwować</a>
            <img src={arrow} alt="book" title='book' width={30} height={8}/>
          </button>
        </div>
        <HotelsSlider images={hotel.images} />
      </div>
    ))}
    </>
  )
}


const SliderSection = ({hotels}) => {
  return (
    <section className={styles.information}>
      <h2 className={styles.sectionHeader}>Oferuje</h2>
      <p className={styles.sectionText}>Najlepsze oferty hotelowe</p>
      <HotelsSectionSlider hotels={hotels}/>
    </section>
  );
};

export default SliderSection;