import React from 'react';
import styles from '../Main/main.module.css'; // Подключаем модуль стилей
import Czech1 from '../Assets/wegry1.webp';
import Czech2 from '../Assets/wegry2.webp';
import Czech3 from '../Assets/wegry3.webp';
import Offerta1 from '../Assets/wegryhotel1.webp';
import Offerta12 from '../Assets/wegryhotel4.webp';
import Offerta13 from'../Assets/wegryhotel7.webp'
import HotelPolski1 from '../Assets/wegryhotel2.webp';
import HotelPolski12 from '../Assets/wegryhotel3.webp';
import HotelPolski13 from '../Assets/wegryhotel5.webp';
import HotelPolski2 from '../Assets/wegryhotel6.webp';
import HotelPolski22 from '../Assets/wegryhotel8.webp';
import HotelPolski23 from '../Assets/wegryhotel9.webp';
import { HeroSection } from '../Main/main';
import { HotelsSectionSlider } from '../Main/SliderPage';

const wegryImages = [Czech3, Czech1, Czech2, Czech3, Czech1, Czech2 ];
const wegryHotels = [
  {
    name: "Hotel Clark Budapest",
    description: "Hotel Clark Budapest – Adults Only to wysoko oceniany hotel położony w samym sercu Budapesztu, zaledwie kilka kroków od Dunaju i słynnego mostu Széchenyi. Oferuje gościom luksusowe i romantyczne wakacje w atmosferze elegancji i prywatności.",
    rating: "9.2",
    address: "1013 Budapeszt, Clark Adam tér 1., Węgry",
    events: [
      {
        title: "Spa",
        description: "Podaruj sobie wyjątkowe doświadczenie spa w stylowym hotelowym spa."
      },
      {
        title: "Centrum fitness",
        description: "Zadbaj o formę w najnowocześniejszym centrum fitness, wyposażonym w najnowocześniejszy sprzęt."
      }
    ],
    images: [Offerta1, HotelPolski1, HotelPolski12],
    bookingLink: "https://www.booking.com/hotel/hu/clark-budapest.pl.html?aid=356980&label=gog235jc-1DCAsoZ0IOY2xhcmstYnVkYXBlc3RIM1gDaLYBiAEBmAEeuAEXyAEV2AED6AEBiAIBqAIDuALH9NWzBsACAdICJDA2YzE3ZTg3LTg3ZTctNDM0NC1iZDNiLWE0OTkwOWI3YWU1MtgCBOACAQ&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
  },
  {
    name: "ZOYA LUXURY RESIDENCE",
    description: "ZOYA LUXURY RESIDENCE to wysoko oceniany hotel położony w Budapeszcie na Węgrzech. Oferuje luksusowe apartamenty z panoramicznym widokiem na miasto i Dunaj, zlokalizowane w historycznym centrum Budapesztu.",
    rating: "9.3",
    address: "082 Budapeszt, 19 Horváth Mihály tér, Węgry",
    events: [
      {
        title: "Zwiedzanie",
        description: "Hotel położony jest w bliskiej odległości od głównych ulic Budapesztu, gdzie można spacerować historycznymi uliczkami, zwiedzać muzea i galerie oraz relaksować się w kawiarniach i restauracjach."
      }
    ],
    images: [Offerta12, HotelPolski13, HotelPolski2],
    bookingLink: "https://www.booking.com/hotel/hu/zoya-luxury-residence.pl.html?aid=356980&label=gog235jc-1DCAsoZ0IVem95YS1sdXh1cnktcmVzaWRlbmNlSDNYA2i2AYgBAZgBHrgBF8gBFdgBA-gBAYgCAagCA7gC2vTVswbAAgHSAiQ2NmYyMDQ5OS0wOTY1LTRlOTktYmE4Yi0xNGJkYTZjMDNhOGbYAgTgAgE&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
  },
  {
    name: "Mystery Hotel Budapest",
    description: "Mystery Hotel Budapest to wysoko oceniany hotel położony w Budapeszcie na Węgrzech. Oferuje gościom przytulne i stylowe pokoje, owiane atmosferą intrygi i tajemnicy, zlokalizowane w historycznym centrum miasta.",
    rating: "9.1",
    address: "064 Budapeszt, 45 Podmaniczky utca, Węgry",
    events: [
      {
        title: "Zwiedzanie",
        description: "Hotel położony jest w bliskiej odległości od głównych ulic Budapesztu, gdzie można spacerować historycznymi uliczkami, zwiedzać muzea i galerie oraz relaksować się w kawiarniach i restauracjach."
      },
      {
        title: "Wycieczki",
        description: "Hotel może pomóc w organizacji wycieczek po Pradze i okolicach."
      }
    ],
    images: [Offerta13, HotelPolski22, HotelPolski23],
    bookingLink: "https://www.booking.com/hotel/hu/mystery-budapest.pl.html?aid=356980&label=gog235jc-1DCAsoZ0IQbXlzdGVyeS1idWRhcGVzdEgzWANotgGIAQGYAR64ARfIARXYAQPoAQGIAgGoAgO4Au301bMGwAIB0gIkODc1OWQ3ZTMtOTA1Mi00MThlLWJlNWEtNWRmMGZmNGRlMWZk2AIE4AIB&sid=86777a981f210741289092eaaa1594d4&dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&"
  }
];

const Main = () => (
  <main>
    <HeroSection images={wegryImages} title={'Węgry'}/>
    <section className={styles.information}>
      <h2 className={styles.sectionHeader}>Hotele</h2>
      <HotelsSectionSlider hotels={wegryHotels} />
    </section>
  </main>
);

export default Main;