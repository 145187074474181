import React, { useState } from 'react';
import styles from './main.module.css'; // Импортируем стили
import Sportbar1 from '../Assets/sportbar1.webp';
import Sportbar2 from '../Assets/sportbar2.webp';

import Sportbar1Mob from '../Assets/sportbar1Mob.webp';
import Sportbar2Mob from '../Assets/sportbar2Mob.webp';
import TransmisjeSlider from '../TransmisjeSlider/TransmisjeSlider';

const TransmisjeSection = () => {
    const isMobile = window.innerWidth < 775;
    return (
        <section className={styles.transmisjeSection}>
                <div className={styles.sportsFans}>
                    <div className={styles.sportsFansContainer}>
                        <h2>Dla miłośników sportu</h2>
                        <h3>Odwiedź bar sportowy</h3>
                        <p>
                            Zapraszamy do zanurzenia się w świat wydarzeń sportowych, miłego oglądania meczów na dużych ekranach w komfortowych warunkach.
                            Dla tych, którzy kochają emocje, mamy bezpieczne i nowoczesne automaty, w których każdy może spróbować szczęścia.
                        </p>
                    </div>
                    <div className={styles.images}>
                        <img src={isMobile ? Sportbar1Mob : Sportbar1} alt="Sports fans in bar" title='fans' width={'352'} height={'418'} loading='lazy'/>
                        <img src={isMobile ? Sportbar2Mob : Sportbar2} alt="People enjoying sports in bar" title='fans' width={'352'} height={'418'} loading='lazy'/>
                    </div>
                </div>
                <div className={styles.transmissions}>
                    <h2>Transmisje</h2>
                    <TransmisjeSlider/>
                </div>
        </section>
    );
};

export default TransmisjeSection;